import React from "react"
import { graphql } from "gatsby"
import ProductDetails from "components/Shop/ProductDetails"
import Footer from "components/Footer"
import Header from "components/Header"
import Sticky from "react-stickynode"
import KarateFont from "../fonts/fonts"

const ProductTemplate = ({ data }) => {
  const product = data.shopifyProduct
  return (
    <div>
      <KarateFont/>
      <Sticky innerZ={1}>
        <Header showCartIcon={true}/>
      </Sticky>
      <ProductDetails data={product} />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fixed(height: 350, width: 350) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
export default ProductTemplate
