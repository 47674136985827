import React, { useState, useEffect, useMemo } from "react"
import Img from "gatsby-image"
import { Carousel } from "react-bootstrap"
import { prepareVariantsWithOptions } from "../../../utils/shop"
import { useCheckout, useAddItemToCart } from "../../../context/StoreContext"
import toast, { Toaster } from "react-hot-toast"

const ProductDetails = ({ data }) => {
  const product = data
  const variantValue = product.variants[0].selectedOptions[0].value

  const variants = useMemo(() => prepareVariantsWithOptions(product.variants), [
    product.variants,
  ])

  const checkout = useCheckout()
  const addItemToCart = useAddItemToCart()
  const [variant, setVariant] = useState(variants[0])
  const [size, setSize] = useState(variant.size)

  useEffect(() => {
    const newVariant = variants.find(variant => {
      return variant.size === size
    })

    if (variant.shopifyId !== newVariant.shopifyId) {
      setVariant(newVariant)
    }
  }, [size, variants, variant.shopifyId])

  function handleAddToCart() {
    toast("Item added to cart!", {
      icon: "🖤",
    })
    addItemToCart(variant.shopifyId, 1)
  }

  function handleBuyItNow() {
    addItemToCart(variant.shopifyId, 1)
    checkout()
  }

  function NewlineText(props) {
    const text = props.text
    const newText = text.split("/n").map(str => <p class="text-center">{str}</p>)

    return newText
  }

  return (
    <div class="container" style={{ paddingBottom: "120px" }}>
      <Toaster />
      <div class="container h-100 d-flex justify-content-center">
        <Carousel style={{ width: "350px", height: "350px" }}>
          {product.images.map(image => (
            <Carousel.Item>
              <Img fixed={image.localFile.childImageSharp.fixed} />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div class="d-flex justify-content-center" style={{ margin: "20px" }}>
        <h4 style={{ fontFamily: "Karate" }}>{product.title}</h4>
      </div>
      <div>
        <NewlineText text={product.description} />
      </div>
      <div class="d-flex justify-content-center">
        <p class="font-weight-bold">
          €{product.priceRange.maxVariantPrice.amount}
        </p>
        </div>
        <div class="d-flex justify-content-center">
        {variantValue !== "Default Title" && (
          <div class="d-flex form-group">
            <label style={{marginRight: "20px", marginTop: "4px"}}>Size</label>
            <select
              class="form-control"
              id="sizeForm"
              onChange={event => setSize(event.target.value)}
            >
              {product.variants.map(options => (
                <option>{options.selectedOptions[0].value}</option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div class="d-flex justify-content-center">
        <div class="btn-group" role="group">
          <button
            type="button"
            class="btn"
            style={{
              marginRight: "10px",
              background: "#FFDA00",
              color: "#FF00E7",
              borderColor: "#FF00E7",
              borderWidth: "5px",
              fontFamily: "Karate",
            }}
            onClick={handleBuyItNow}
          >
            BUY IT NOW
          </button>
          <button
            type="button"
            class="btn"
            style={{
              marginLeft: "10px",
              background: "#FFDA00",
              color: "#000000",
              borderColor: "#000000",
              borderWidth: "5px",
              fontFamily: "Karate",
            }}
            onClick={handleAddToCart}
          >
            ADD TO CART
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductDetails
